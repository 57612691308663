@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.cdnfonts.com/css/gotham');
@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.cdnfonts.com/css/mechanical');

body {
  font-family: 'Gotham', 'Poppins', 'Mechanical','Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   /* Ocultar barra de scroll pero mantener el scroll funcional */
   overflow-y: scroll; /* Scroll vertical funcional */
   scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Chrome, Safari y Edge */
}


:root {
  --colorPrimary-Blue: #2A6EDB;
  --colorPrimary-Green: #42C67E;
  --colorPrimary-Black: #232323;
  
  --colorSecondary-Green: #70DB83;
  --colorSecondary-Blue: #1AA4CC;
  --colorSecondary-Black: #959595;
  --colorSecondary-Gray: #E3E3E3;

  --colorPrimary-Error: #FF655A;

  --colorTertiary-Blue: #4884E2;

  --colorNeutral-Blanco: #FFF;
  --blur-shadow-to-button: rgba(42, 110, 219, 0.25) 0px 54px 55px, rgba(42, 110, 219, 0.12) 0px -12px 30px, rgba(42, 110, 219, 0.12) 0px 4px 6px, rgba(42, 110, 219, 0.17) 0px 12px 13px, rgba(42, 110, 219, 0.09) 0px -3px 5px;

}

/* .css-1nmlzef {
  height: initial !important;
}

.css-1u2w381-MuiModal-root-MuiDrawer-root {
  position: fixed !important;
  z-index: 9999999 !important;
} */

a {
  height: fit-content !important;
}

/* .css-10ipmgy-MuiPaper-root-MuiCard-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
} */

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  border-top-left-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
}

.MuiDialog-paper {
  margin-left: 28px !important;
  margin-right: 28px !important;
} */

/* .css-ypiqx9-MuiDialogContent-root {
  padding: 0px 0px !important;
}

.css-12rl710-MuiPaper-root-MuiDialog-paper {
  border-radius: 20px !important;
  margin: 10px !important;
}

.css-1ty026z {
  padding: 0 !important;
}

.css-1qmc5dd {
  border-radius: 20px !important;
}

.MuiDialog-paperScrollPaper {
  border-radius: 10px !important;
}

.css-yz9k0d-MuiInputBase-input {
  width: 70px !important;
  text-align: justify !important;
  text-justify: inter-word !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-width: 50% !important;
}

.css-1ujnqem-MuiTabs-root {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.MuiInputBase-root {
  margin-left: 25px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorBottom.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.MuiBox-root .css-1nm1zef {
  position: relative !important;
  z-index: 25 !important;
  height: initial !important;
} */

a {
  text-decoration: none !important;
}

/* .css-kjnuyh-MuiPaper-root-MuiCard-root {
  border-radius: 10px !important;
} */

/* .css-csmqbv-MuiPaper-root-MuiDialog-paper {
  width: 90vw !important;
  margin: 0 !important;
} */

/* .css-jcn4dz-MuiBadge-badge {
  background-color: #70DB83 !important;
}

.css-10ipmgy-MuiPaper-root-MuiCard-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
} */

/*FORMULARIO DE ALIGNET*/

/* Media queries for different screen sizes */
@media (min-width: 600px) { /* sm */
  .field label {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 900px) { /* md */
  .field label {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1200px) { /* lg and xl */
  .field label {
    font-size: 16px;
    line-height: 24px;
  }
}


/* .form-wrapper {
 
 
 
  
} */

/* h2 {
  text-align: center;
  margin-bottom: 15px;
  color: #333; 
  display: none;
} */

.field {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  flex-shrink: 0;
  gap: 12px;
  position: relative;
  z-index: 8;
}

.field label {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #88919e;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 19;
}

.field input {
  border-radius: 4px;
  height: 48px; /* Default height for small devices */
  box-shadow: 0px 4px 12px 0px rgba(25, 67, 127, 0.12);
  border: none;
  width: 100%;
  padding-left: '10px';
}

.field.field-v_pan input {   
  /* Set padding and border radius */
  padding: 10px;
  border-radius: 4px; 
}

.field.field-v_expirydate input {   
  /* Set padding and border radius */
  padding: 10px;
  border-radius: 4px;
}

.field.field-v_cvv2 input {   
  /* Set padding and border radius */
  padding: 10px;
  border-radius: 4px;
}

.field.field-v_pan input:focus {
  outline: none; /* Remove default outline */  
  box-shadow: 0 0 5px #42c67e; /* Stronger shadow effect on focus */
}

.field.field-v_pan.has-value.invalid input {
  /* Set the background color */
  background-color: #f8d7da; /* Light red background for invalid input */
  
  /* Set the border color */
  /*border: 1px solid #dc3545;*/ /* Red border for invalid input */
  
  /* Change the text color */
  color: #721c24; /* Dark red text color for better visibility */
  
  /* Set padding and border radius */
  padding: 10px;
  border-radius: 4px;

  /* Optional: Set box shadow */
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5); /* Subtle shadow for focus effect */
}

.field.field-v_pan.has-value.invalid input:focus {
  outline: none; /* Remove default outline */
  /*border-color: #c82333; *//* Darker red border on focus */
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.7); /* Stronger shadow effect on focus */
}

.field.field-v_expirydate input:focus {
  outline: none; /* Remove default outline */  
  box-shadow: 0 0 5px #42c67e; /* Stronger shadow effect on focus */
}

.field.field-v_expirydate.has-value.invalid input {
  /* Set the background color */
  background-color: #f8d7da; /* Light red background for invalid input */  
 
  /* Change the text color */
  color: #721c24; /* Dark red text color for better visibility */
  
  /* Set padding and border radius */
  padding: 10px;
  border-radius: 4px;

  /* Optional: Set box shadow */
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5); /* Subtle shadow for focus effect */
}

.field.field-v_expirydate.has-value.invalid input:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.7); /* Stronger shadow effect on focus */
}

.field.field-v_cvv2 input:focus {
  outline: none; /* Remove default outline */  
  box-shadow: 0 0 5px #42c67e; /* Stronger shadow effect on focus */
}

.field.field-v_cvv2.has-value.invalid input {
  /* Set the background color */
  background-color: #f8d7da; /* Light red background for invalid input */
  
  /* Set the border color */
  /*border: 1px solid #dc3545;*/ /* Red border for invalid input */
  
  /* Change the text color */
  color: #721c24; /* Dark red text color for better visibility */
  
  /* Set padding and border radius */
  padding: 10px;
  border-radius: 4px;

  /* Optional: Set box shadow */
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5); /* Subtle shadow for focus effect */
}

.field.field-v_cvv2.has-value.invalid input:focus {
  outline: none; /* Remove default outline */
  /*border-color: #c82333; *//* Darker red border on focus */
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.7); /* Stronger shadow effect on focus */
}


.error-message {
  color: red; /* Error messages in red */
  font-size: 0.875em; /* Smaller font size */
}

.submit {  
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 10px;
}

.submit button {
  width: 360px; /* Makes the button occupy full width */
  height: 48px;
  padding: 12px, 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 30px;
  background: #42C67E;
  box-shadow: 0px 2px 10px 0px rgba(30, 41, 71, 0.14);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  border: none;
  color: white;
}

.submit button:disabled {
  background-color: #ccc; /* Grey when disabled */
  cursor: not-allowed; /* Change cursor on disabled button */
}

.submit button:hover:not(:disabled) {
  background: var(--colorSecondary-Green);
  box-shadow: 0px 6px 16px 0px rgba(25, 67, 127, 0.32);
}

